export function formatSize(s) {
  if (!isNaN(s)) {
    let size = Math.ceil(s / 1024);
    if (size <= 1024) {
      return new Intl.NumberFormat('ru-RU', {
        maximumFractionDigits: 0,
        style: 'unit',
        unit: 'kilobyte',
      }).format(size);
    } else {
      return new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: 'unit',
        unit: 'megabyte',
      }).format(Math.ceil(size / 10.24) / 100);
    }
  }
  return '';
}
