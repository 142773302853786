<template lang="pug">
.message(:class="{ 'my-own': message.my }")
  .avatar
    .thumbnail-wrap
      v-img.thumbnail(:src="message.lead.avatar_url")
  .message-body
    .images-list(v-if="images.length")
      v-img.message-image(
        v-for="i in (images.length == 2 ? 2 : 1)"
        :key="images[i - 1].frontId"
        :src="images[i - 1].document_url"
        :aspect-ratio="16 / 9"
        @click="onImageClick(i)"
      )
      .images-count(v-if="images.length > 2")
        span {{ images.length }}
        ui-icon.ml-1(name="image-outline" size="16")
      //- :style="{ backgroundImage: 'url(' + image.document_url + ')' }"
    .caption-mid.color-gray70 {{ message.lead.name }}
    .mt-1.body-text(v-html="message.body")
    .files-list(v-if="files.length")
      a.file(
        v-for="file in files"
        :key="file.frontId"
        download
        :href="file.document_url"
      )
        ui-icon.mr-1.c-gray80(size="32" :name="getIconByExtension(file.extension)")
        .file-text
          .file-name {{ file.name }}
          .file-info
            span.caps {{ file.extension }}
            | {{ " / " + formatSize(file.size) }}
  .message-status
    ui-icon.color-primary60(
      v-if="message.my"
      :name="message.viewed ? 'check-double' : 'check-single'"
    )
    .caption-small.color-gray70.mt-1 {{ formatTime(message.created_at) }}
  gallery(
    v-if="images.length"
    v-model="showGallery"
    :current-image.sync="currentImage"
    :images="images"
  )
</template>

<script>
  import { UiIcon } from '@/uikit';
  import Gallery from './Gallery.vue';
  import { formatSize } from '@/libs/helpers';

  export default {
    name: 'message',
    components: {
      UiIcon,
      Gallery,
    },
    props: {
      message: {
        type: Object,
        default: () => ({
          body: '',
          created_at: 0,
          documents: [],
          lead: {
            avatar_url: '',
            name: '',
          },
          my: false,
          viewed: false,
        }),
        required: true,
      },
    },
    data() {
      return {
        showGallery: false,
        currentImage: 0,
      };
    },
    computed: {
      images() {
        return this.message.documents.filter((m) => m.type == 'image') || [];
      },
      files() {
        return this.message.documents.filter((m) => m.type !== 'image') || [];
      },
    },
    methods: {
      formatSize,
      formatTime(timestamp) {
        if (!timestamp) return '';
        const date = new Date(timestamp);
        if (date.valueOf()) {
          return `${('' + date.getHours()).padStart(2, 0)}:${('' + date.getMinutes()).padStart(
            2,
            0
          )}`;
        }
        return '';
      },
      getIconByExtension(e) {
        const extension = (e || '').toLowerCase();
        if (['doc', 'docx'].indexOf(extension) > -1) {
          return 'adm-file-doc-32';
        }
        if (extension == 'zip') {
          return 'adm-file-zip-32';
        }
        if (extension == 'pdf') {
          return 'adm-file-pdf-32';
        }
        return 'adm-file-default-32';
      },
      onImageClick(i) {
        this.currentImage = i - 1;
        this.showGallery = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .message {
    display: flex;
    flex: 1;
    max-width: 100%;
    & + .message {
      margin-top: 16px;
    }
  }
  //
  .avatar {
    width: 40px;
    flex: none;
  }

  .message-status {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 65px;
    flex: none;
    padding-left: 8px;
  }

  .thumbnail-wrap {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid c('gray10', 'light');
  }
  .thumbnail {
    flex: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid white;
  }

  .message-body {
    flex: 1;
    background-color: c('gray5', 'light');
    border-radius: 12px;
    padding: 12px 16px;
    max-width: calc(100% - 105px);
  }
  .body-text {
    color: c('gray100', 'light');
    word-break: break-word;
    ::v-deep {
      p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
      }
    }
  }
  .my-own {
    .avatar {
      display: none;
    }
    .message-status {
      width: 105px;
      order: 1;
      align-items: flex-end;
      padding-right: 8px;
    }
    .message-body {
      order: 2;
      background-color: c('primary5', 'light');
    }
  }

  .images-list {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 32px;
    margin-top: 4px;
    margin-bottom: 12px;
    max-width: 100%;
    overflow: hidden;
  }

  .images-count {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 32px;
    max-height: 100px;
    align-items: flex-end;
    justify-content: flex-end;
    color: white;
    font-size: 14px;
    line-height: 16px;
    line-height: 1;
    padding: 8px;
    background: linear-gradient(180deg, rgba(37, 40, 43, 0) 0%, rgba(37, 40, 43, 0.6) 100%);
    border-radius: 0px 0px 4px 4px;
  }

  .files-list {
    margin-top: 14px;
    margin-bottom: 4px;
  }

  .file {
    display: flex;
    max-width: 100%;
    text-decoration: none;
    & + .file {
      margin-top: 16px;
    }
  }

  .file-text {
    max-width: calc(100% - 36px);
  }

  .file-name {
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: c('gray100', 'light');
  }

  .file-info {
    font-size: 11px;
    line-height: 16px;
    margin-top: 2px;
    color: c('gray80', 'light');
  }

  .message-image {
    width: 100%;
    max-width: 100%;
    border-radius: 4px;
    // min-height: 138px;
    // height: 138px;
    // max-height: 138px;
    & + .message-image {
      margin-top: 4px;
    }
  }
</style>
