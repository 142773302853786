<template lang="pug">
.file-thumbnail
  .image-bg(
    v-if="file.type == 'image'"
    :style="{ backgroundImage: 'url(' + file.document_url + ')' }"
  )
  .doc-wrap(v-if="file.type == 'file'")
    ui-icon.c-gray70(name="file-with-lines" size="28")
    .name {{ file.raw.name }}
    .file-info {{ fileInfo }}
  .close-btn(@click="onDelete(file.frontId)")
    .circle
      ui-icon(name="close-2" size="16")
</template>

<script>
  import { UiIcon } from '@/uikit';
  import { mapMutations } from 'vuex';
  import { formatSize } from '@/libs/helpers';
  export default {
    name: 'file-thumbnail',
    components: { UiIcon },
    props: {
      file: {
        type: Object,
        default: () => ({}),
        required: true,
      },
    },
    computed: {
      fileInfo() {
        return this.file.extension + ' / ' + this.formatSize(this.file?.raw?.size);
      },
    },
    methods: {
      formatSize,
      ...mapMutations('interactions', ['deleteFile']),
      onDelete(frontId) {
        this.deleteFile(frontId);
        this.$emit('delete');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .file-thumbnail {
    position: relative;
    flex: none;
    width: 100px;
    height: 80px;
    margin: 12px 4px 0;
    border: 1px solid c('gray10', 'light');
    border-radius: 4px;
    overflow: hidden;
  }

  .name {
    white-space: nowrap;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: c('gray80', 'light');
    margin: 4px 2px 0 2px;
    font-size: 12px;
    line-height: 16px;
  }

  .file-info {
    font-size: 10px;
    line-height: 16px;
    color: c('gray70', 'light');
    text-transform: uppercase;
  }

  .image-bg {
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .doc-wrap {
    display: flex;
    flex-direction: column;
    padding: 8px;
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 8px 0 0;

    .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      color: c('gray70', 'light');
      background-color: rgba(white, 0.9);
      border-radius: 50%;
      border: 1px solid c('gray70', 'light');
    }
  }
</style>
