<template lang="pug">
v-app-bar.page-header(app height="48")
  .t-h3-mobile.c-black(v-if="title") {{ title }}
  ui-icon.close-btn.c-black70(name="close" @click="close")
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import { UiBtn, UiIcon } from '@/uikit';

  export default {
    name: 'page-header',
    components: { UiBtn, UiIcon },

    props: {
      title: { type: String, default: undefined },
    },

    computed: {
      ...mapGetters(['currentUser']),
      ...mapState('interactions', ['assetPath']),
    },

    methods: {
      ...mapActions(['signIn']),

      close() {
        this.$store.dispatch('catalog/setPreinitialized', true);
        this.$emit('close');
        this.$router.back();
      },
    },
  };
</script>

<style lang="scss">
  .page-header {
    background-color: c('white', 'light') !important;
    // box-shadow: none !important;

    .v-toolbar__content {
      display: flex;
      justify-content: center;
      position: relative;
      padding: 4px 34px;
    }
  }

  .close-btn {
    position: absolute;
    right: 14px;
    top: 12px;
  }
</style>
