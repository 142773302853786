<template lang="pug">
div
  div(:style="{ height: height + 'px' }")
  .page-interaction-dialog__toolbar(ref="toolbar")
    .files-line
      file-thumbnail(
        v-for="file in files"
        :key="file.frontId"
        :file="file"
        @delete="onDelete"
      )
      pre {{ files[0] && files[0].name }}
    .input-line
      ui-btn.px-1.mr-1.mb-2(depressed @click="onAddFileClick")
        ui-icon.color-gray70(name="plus")
      v-textarea.textarea-input(
        v-model="inputText"
        placeholder="Напишите сообщение..."
        auto-grow
        flat
        hide-details
        no-resize
        rows="1"
        solo
        @input="onTextareaInput"
      )
      ui-btn.px-1.ml-1.mb-2(depressed @click="send" :loading="sendLoading")
        ui-icon.color-gray70(name="send")
  input.hidden(
    ref="file-input"
    type="file"
    multiple
    @change="onFileChange"
  )
  v-dialog(v-bind="dialogOptions" v-model="showValidationErrors")
    v-card.error-card.c-gray100.align-start
      template(v-if="validationErrors.length > 1")
        h2.w-100.text-center Неподходящие файлы
        p.mt-2 Не будут добавлены в сообщение:
      template(v-else)
        h2.w-100.text-center Неподходящий файл
        p.mt-2 Не будет добавлен в сообщение:
      p.mt-2(v-if="errorsSize")
        | Размер превышает 10 МБ:
        |
        span.error--text {{ errorsSize }}
      p.mt-2(v-if="errorsExtension")
        | Тип файла не принимается:
        |
        span.error--text {{ errorsExtension }}

      ui-btn.mt-6.btn-outlined-block(outlined @click="showValidationErrors = false") Закрыть
  v-dialog(v-bind="dialogOptions" :value="showDocErrors")
    v-card.error-card.c-gray100.align-start
      h2.w-100.text-center Ошибка
      p.mt-2 Прикреплённые файлы не могут быть отправлены по причине:
      .mt-2 
        p(v-for="(error, i) in docErrors" :key="'error' + i") {{ error }}
      ui-btn.mt-6.btn-outlined-block(
        outlined
        @click="$store.commit('interactions/setValue', { field: 'showDocErrors', value: false })"
      ) Закрыть
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';
  import { mapMutations, mapActions, mapState } from 'vuex';
  import FileThumbnail from './FileThumbnail.vue';

  export default {
    name: 'message-editor',
    components: { UiBtn, UiIcon, FileThumbnail },
    props: {
      dialogId: {
        type: [Number, String],
        default: '',
      },
    },
    data() {
      return {
        inputText: '',
        height: 48,
        sendLoading: false,
        validationErrors: [],
        showValidationErrors: false,
        dialogOptions: {
          'content-class': 'shadow-middle',
          'max-width': '350',
          'overlay-color': 'black',
          'overlay-opacity': 0.6,
          width: 280,
        },
      };
    },
    computed: {
      ...mapState('interactions', [
        'files',
        'extensionsFiles',
        'extensionsImages',
        'docErrors',
        'showDocErrors',
      ]),
      errorsSize() {
        return this.validationErrors
          .filter((file) => file.type == 'size')
          .map((file) => file.name)
          .join(', ');
      },
      errorsExtension() {
        return this.validationErrors
          .filter((file) => file.type == 'extension')
          .map((file) => file.name)
          .join(', ');
      },
    },
    watch: {
      height(newVal, val) {
        const diff = newVal - val;
        if (diff) {
          this.$nextTick(() => {
            window.scrollTo(0, window.scrollY + diff);
          });
        }
      },
    },
    methods: {
      ...mapMutations('interactions', ['setFiles', 'setValue']),
      ...mapActions('interactions', ['sendMessage']),

      onTextareaInput() {
        this.$nextTick(() => {
          this.equalise();
        });
      },
      async send() {
        if ((this.inputText || this.files.length) && this.dialogId && !this.sendLoading) {
          this.sendLoading = true;
          const response = await this.sendMessage({
            id: this.dialogId,
            body: this.inputText,
          });
          this.sendLoading = false;

          if (response.ok) {
            this.inputText = '';
            // сбрасываем высоту до 48px у div - компенссатора
            setTimeout(() => {
              this.equalise();
            }, 0);
            // после этого плавно прокручиваем экран вниз, к новому сообщению
            setTimeout(() => {
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
              });
            }, 10);
          }
        }
      },
      equalise() {
        // выравниваем высоту компенсатора(отступ под сообщениями) с высотой редактора сообщения
        this.height = (this.$refs['toolbar'] && this.$refs['toolbar'].clientHeight) || 48;
      },
      onAddFileClick() {
        if (this.$refs['file-input']) {
          this.$refs['file-input'].value = null; // стираем старое значение в инпуте
          this.$refs['file-input'].click();
        }
      },
      onFileChange(evt) {
        if (evt && evt.target && evt.target.files) {
          this.validationErrors = []; // удаляем предыдущие ошибки
          let files = Array.from(evt.target.files).map((raw, i) => {
            let fileType = '',
              document_url = '';
            // обрабатываем картинки
            if (/image/i.test(raw.type)) {
              fileType = 'image';
              document_url = URL.createObjectURL(raw);
              URL.revokeObjectURL(raw);
            }

            // обрабатываем разрешенные расширения файлов
            const extension = (raw.name || '').split('.').pop(); // получаем расширение файла
            if (this.extensionsFiles.indexOf(extension) > -1) {
              fileType = 'file';
            }

            // валидируем файл
            let error = '';
            // Размер файла не должен превышать 10 МБ
            if (raw.size > 10485760) {
              error = 'size';
            }
            // если расширение не в ходит ни в один массив разрешенных разрешений изображений или файлов (список взят из yaml house и разделён на два массива: изображения и файлы) - то ошибка
            if (
              this.extensionsFiles.indexOf(extension) < 0 &&
              this.extensionsImages.indexOf(extension) < 0
            ) {
              error = 'extension';
            }

            return {
              frontId: new Date().getTime() + '-' + i,
              raw,
              document_url,
              extension,
              type: fileType,
              error,
            };
          });

          this.validationErrors = files
            .filter((file) => file.error)
            .map((file) => ({ name: file.raw.name, type: file.error }));
          // если есть неподходящие файлы то показываем ошибки
          if (this.validationErrors.length) {
            this.showValidationErrors = true;
          }
          files = files.filter((file) => !file.error); // удаляем из массива все неподходящие файлы

          // сохраняем массив файлов в модель сообщения
          this.setFiles(files);
          this.$nextTick(() => {
            this.equalise();
          });
        }
      },
      onDelete() {
        setTimeout(() => {
          this.equalise();
        }, 0);
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight,
          });
        }, 10);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .page-interaction-dialog__toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: white;

    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.24);
  }

  .input-line {
    display: flex;
    align-items: flex-end;
    min-height: 48px;
    padding: 0 16px;
  }

  .textarea-input {
    padding-top: 0;
    ::v-deep {
      textarea {
        font-size: 15px;
        line-height: 22px;
      }
    }
  }

  .hidden {
    display: none;
  }

  .files-line {
    max-width: 100%;
    display: flex;
    padding: 0 12px;
    overflow-x: auto;
  }
</style>
