<template lang="pug">
app-layout.interactions
  v-app.layout-full
    page-header.sh-xsmall(title="Сообщения")
    v-main.page-interaction-dialog
      v-overlay.text-center(
        :value="loader"
        color="#fff"
        absolute
        opacity="1"
      )
        .c-gray100(v-if="errorMessage") Ошибка отправки сообщения
        template(v-else)
          ui-icon.c-primary.animation-rotate(name="loadings" size="60")
          .caption-small.c-gray80.mt-1 Отправка сообщения...
      .dialog-header(:class="{ slide, notransition }")
        .btn-back
          router-link.back-link(to="/interactions")
            ui-icon.c-black70(name="arrow-left" size="28")
        .asset-info(v-if="exist")
          router-link.company(:to="{ name: 'pageAsset', params: { assetId: one.asset.short_id } }")
            v-img.thumbnail(:src="one.asset.photo")
            .flex-1.mw-40
              .p-mid.nowrap {{ one.asset.title }}
              .p-mid.bold {{ one.asset.price }}
          .seller
            .seller-photo-wrap
              v-img.seller-photo(:src="one.contact.avatar_url")
            div
              .p-mid {{ one.contact.name }}
              .caption-small.color-gray70 {{ one.contact.seller }}
      v-spacer
      v-container
        .messages(v-if="exist")
          message(v-for="(message, i) in one.messages" :key="'m' + i" :message="message")
        message-editor(:loading.sync="loader" :dialog-id="id" :errorMessage.sync="errorMessage")
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import PageHeader from '@/components/app/PageHeader.vue';
  import Message from '@/components/messages/Message.vue';
  import MessageEditor from '@/components/messages/MessageEditor.vue';

  import { UiBtn, UiIcon } from '@/uikit';
  import { mapState, mapMutations, mapActions /*, mapGetters,*/ } from 'vuex';

  export default {
    name: 'page-interactions-dialog',

    components: {
      AppLayout,
      PageHeader,
      UiBtn,
      UiIcon,
      Message,
      MessageEditor,
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        // если пришли со страницы актива, то сохранияем путь для возврата в актив
        if (from.name == 'pageAsset') {
          vm.$store.commit('interactions/setValue', { field: 'assetPath', value: from.path });
        }
        // если пришли не со страницы списка диалогов /interactions то устанавливаем флаг, что нужно запросить диалог
        if (from.name != 'pageInteractions') {
          vm.needRequestDialog = true;
        }
      });
    },
    props: {
      id: {
        type: [Number, String],
        default: '',
      },
    },

    data: () => ({
      // dialogLoading: false,
      slide: false,
      scrollY: 0,
      notransition: true,
      loader: false,
      errorMessage: false,
      needRequestDialog: false,
    }),

    computed: {
      // ...mapGetters(['currentUser']),
      ...mapState('interactions', ['one']),
      exist() {
        return !!this.one.number;
      },
    },

    created() {
      window.addEventListener('scroll', this.onScroll);
    },
    mounted() {
      if (this.needRequestDialog) {
        // если диалог ещё не загружался, то запрашиваем его по id из url (передаётся через router)
        if (this.id) {
          this.dialogLoading = true;
          this.requestDialog(this.id).then((resp) => {
            this.dialogLoading = false;
            if (resp.ok) {
              this.$nextTick(() => {
                this.scrollToBottom();
              });
            }
          });
        }
      } else {
        // скролим вних, если загрузка диалога была на странце PageInteractions.vue
        this.scrollToBottom();
      }
    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.onScroll);
      this.deleteAllFiles();
    },

    methods: {
      ...mapActions('interactions', ['requestDialog', 'sendMessage']),
      ...mapMutations('interactions', ['deleteAllFiles']),
      onScroll() {
        // если скролл вверх - то показываем dialog-header иначе прячем
        this.slide = window.scrollY > this.scrollY;
        this.scrollY = window.scrollY;
      },
      scrollToBottom() {
        window.scrollTo(0, document.body.scrollHeight);
        setTimeout(() => {
          this.notransition = false;
        }, 10);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .page-interaction-dialog {
    ::v-deep {
      .v-main__wrap {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .dialog-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 48px;
    background-color: white;
    display: flex;
    border-bottom: 1px solid c('gray10', 'light');
    min-height: 96px;
    z-index: 4;
    transition: transform 0.3s ease-in;
    &.notransition {
      transition: none;
    }
    &.slide {
      transform: translateY(-100%);
    }
  }

  .messages {
    padding-top: 112px;
  }

  .btn-back {
    width: 50px;
    flex: none;
  }

  .back-link {
    display: flex;
    justify-content: center;
    padding: 12px;
  }

  .asset-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding-right: 16px;
    max-width: calc(100% - 50px);
    color: c('gray100', 'light');
  }
  a.company {
    text-decoration: none;
    color: inherit;
  }

  .company {
    display: flex;
    align-items: center;
    border-bottom: 1px solid c('gray10', 'light');
    min-height: 50px;
  }

  .thumbnail {
    width: 32px;
    height: 32px;
    flex: none;
    border-radius: 3px;
    margin-right: 12px;
  }

  .nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 40px);
  }

  .mw-40 {
    max-width: calc(100% - 40px);
  }
  .seller {
    display: flex;
    align-items: center;
    padding: 6px 0;
  }

  .seller-photo {
    width: 30px;
    height: 30px;
    flex: none;
    border-radius: 50%;
    border: 1px solid white;
  }

  .seller-photo-wrap {
    display: flex;

    flex: none;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border: 1px solid c('gray10', 'light');
    border-radius: 50%;

    margin-right: 12px;
  }
</style>
