<template lang="pug">
v-fade-transition
  .gallery(v-if="value")
    .top-bar
      a.a-text(download :href="images[currentImage].document_url")
        ui-icon(name="download")
      span {{ currentImage + 1 + "/" + images.length }}
      ui-icon(name="close" @click="$emit('input', false)")
    v-carousel(
      :value="currentImage"
      hide-delimiters
      :show-arrows="false"
      height="100%"
      @change="$emit('update:current-image', $event)"
    )
      v-carousel-item(v-for="image in images" :key="image.frontId")
        .image(:style="{ backgroundImage: 'url(' + image.document_url + ')' }")
</template>

<script>
  import { UiIcon } from '@/uikit';

  export default {
    name: 'gallery',
    components: { UiIcon },
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true,
      },
      images: {
        type: Array,
        default: () => [],
        required: true,
      },
      currentImage: {
        type: Number,
        default: 0,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .gallery {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background: c('gray100', 'light');
    z-index: 300;
  }

  .image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
  }

  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding: 0 14px;
    color: c('gray10', 'light');
  }

  .a-text {
    font-size: 0;
  }
</style>
